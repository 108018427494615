import { showTally } from '~/utils/tally';
import { DropdownMenuItem } from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import useTallyId from '~/hooks/useTallyId';

export default function FeedbackActions({ onCloseMenu }: { onCloseMenu: () => void }) {
  const tallyFormId = useTallyId();

  return (
    <>
      <DropdownMenuItem onClick={onCloseMenu} className="flex w-full py-0 focus:bg-transparent">
        <a
          href="mailto:help@custodynavigator.com"
          className="flex w-full items-center justify-between pb-2 pt-2 text-lg font-semibold text-green-primary-text"
        >
          Contact support
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem onClick={onCloseMenu} className="flex w-full py-0 focus:bg-transparent">
        <Button
          type="button"
          onClick={() => showTally({ formId: tallyFormId || '' })}
          className="flex w-full items-center justify-between p-0 pb-2 pt-2 text-lg font-semibold text-green-primary-text"
        >
          Give feedback
        </Button>
      </DropdownMenuItem>
    </>
  );
}
