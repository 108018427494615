import React from 'react';

import { AnalyticsBrowser, type AnalyticsSnippet } from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

export const analytics = new AnalyticsBrowser();

export default function useLoadSegment({ key }: { key: string }) {
  React.useEffect(() => {
    const loadSegment = async () => {
      analytics
        .load(
          { writeKey: key },
          {
            integrations: {
              'Google Analytics 4 Web': false,
            },
          }
        )
        .catch((error) => console.warn('Segment failed to load ', error));
    };

    if (key) loadSegment();
  }, [key]);
}
